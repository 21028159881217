import TruffleLogo from '../assets/truffle.png'
import HardhatLogo from '../assets/Hardhat.png'
import SolidityLogo from '../assets/solidity .png'
import RustLogo from '../assets/rust.png'
import GoLangLogo from '../assets/golang.png'
import MotokoLogo from '../assets/motoko.png'
import WebLogo from '../assets/web.png'
import EthersLogo from '../assets/ethers.png'
import MetamaskLogo from '../assets/metamask.png'
import ContractWalletLogo from '../assets/wallet-money.png'
import EthereumLogo from '../assets/ethereum.png'
import PolygonLogo from '../assets/polygon-token .png'
import OptimismLogo from '../assets/optimism.png'

export const blockchain = [
    {
        name:"Truffle",
        points:"30",
        price:"10000",
        icon:TruffleLogo
    },
    {
        name:"Hardhat",
        points:"30",
        price:"7500",
        icon:HardhatLogo
    },
    {
        name:"Solidity",
        points:"30",
        price:"15000",
        icon:SolidityLogo
    },
    {
        name:"Rust",
        points:"20",
        price:"10000",
        icon:RustLogo
    },
    {
        name:"GoLang",
        points:"25",
        price:"7500",
        icon:GoLangLogo
    },
    {
        name:"Motoko",
        points:"30",
        price:"5000",
        icon:MotokoLogo
    },
    {
        name:"Web3.js",
        points:"25",
        price:"15000",
        icon:WebLogo
    },
    {
        name:"Ethers.js",
        points:"25",
        price:"10000",
        icon:EthersLogo
    },
    {
        name:"Metamask",
        points:"25",
        price:"15000",
        icon:MetamaskLogo
    },
    {
        name:"Contract Wallet(ERC 4337)",
        points:"30",
        price:"5000",
        icon:ContractWalletLogo
    },
    {
        name:"Ethereum",
        points:"30",
        price:"15000",
        icon:EthereumLogo
    },
    {
        name:"Polygon",
        points:"30",
        price:"10000",
        icon:PolygonLogo
    },{
        name:"Optimism",
        points:"35",
        price:"5000",
        icon:OptimismLogo
    },
    
]